import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { ThemeProvider } from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from 'components/shared/SEO';
import { GlobalStyle, lightTheme, darkTheme } from 'theme/globalStyle';
import Header from 'components/page/Header';
import { useLocalStorage } from 'utils/localStorage';
import Main from 'components/page/Main';
import { H1 } from 'styles/content';

const Excerpt = styled.p`
  font-size: 2.4rem !important;
  margin: 2rem 0 !important;
`;

function BlogPost({ data, pageContext }) {
  const { next, prev } = pageContext;
  const { body, frontmatter } = data.mdx;
  const { title, excerpt, featuredImage } = frontmatter;
  const featuredImgFluid = featuredImage.childImageSharp.fluid;

  const [storage, setStorage] = useLocalStorage('theme', {});

  const handleThemeChange = evt => {
    const theme = evt.target.value === 'lightTheme' ? lightTheme : darkTheme;
    setTheme({ theme });
  };

  const [theme, setTheme] = useState({
    theme: storage.theme || lightTheme,
  });

  useEffect(() => {
    setStorage(theme);
  }, [setStorage, theme]);

  return (
    <ThemeProvider theme={theme}>
      <Main>
        <article>
          <GlobalStyle />
          <SEO
            post={{
              ...data,
              image: featuredImgFluid,
            }}
          />
          <Header handleThemeChange={handleThemeChange} />
          <H1>{title}</H1>
          <Img fluid={featuredImgFluid} alt={title} />
          <Excerpt>{excerpt}</Excerpt>
          <MDXRenderer>{body}</MDXRenderer>
          {next && <Link to={next.frontmatter.path}>Next</Link>}
          {prev && <Link to={prev.frontmatter.path}>Prev</Link>}
        </article>
      </Main>
    </ThemeProvider>
  );
}

export const query = graphql`
  query($pathSlug: String!) {
    mdx(fields: { slug: { eq: $pathSlug } }) {
      frontmatter {
        title
        excerpt
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`;

export default BlogPost;
